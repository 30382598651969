import { colors, Typography } from "@mui/material";

import fontWeight from "../../../styles/mui/fontWeight";

export const mandatoryFieldLabel = () => (
  <Typography
    variant="subtitle1"
    fontWeight={fontWeight.Weight[2]}
    color={colors.red[100]}
    pl={"0.3rem"}
  >
    *
  </Typography>
);
